/* eslint-disable no-console */
import { useContext, useMemo } from 'react'
import useAsyncFn, { AsyncState } from 'react-use/lib/useAsyncFn'
import { currentUserContext } from 'contexts/CurrentUser'

import Entities from 'idb/Entities'

export type TReturn = {
  state: AsyncState<void>
  clearEntitiesDb: () => Promise<void>
}

export function useClearLocalEntitiesDb(currentUserId?: string): TReturn {
  const { currentUser } = useContext(currentUserContext)
  const db = useMemo(() => {
    const uid = String(currentUserId || currentUser?.email)
    if(uid) {
      try {
        return new Entities(uid)
      } catch(err) {
        // console.warn(err)
      }
    } else {
      console.warn('No currentUserId param given to useClearLocalEntitiesDb. Databases might not be cleared correctly.')
    }
  }, [currentUser, currentUserId])

  const [state, clearEntitiesDb] = useAsyncFn(async () => {
    try {
      if(db) {
        console.log('Deleting db:', db.name)
        await db.delete()
      } else {
        console.error('No entities database found to clear.')
      }
    } catch(err) {
      // console.warn(err)
    }
  }, [db])

  return {
    state,
    clearEntitiesDb,
  }
}

export default useClearLocalEntitiesDb
